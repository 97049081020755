import * as React from "react";
import * as PropTypes from "prop-types";
import { Box, Button, Flex, Image, Text } from "@chakra-ui/core";
import { Link } from "react-router-dom";
import { MANIPAL, PROFESSIONAL_CHAPTER_NEWS } from "../../constants/routes";

const Card = ({ name, content, image }) => {
	content = content.slice(0, 200) + " ...";
	return (
		<>
			<Flex mb="2rem" justifyContent="center" align="center">
				<Flex
					bg="white"
					my={["1.5rem", "1rem", "1rem"]}
					mx="1rem"
					w={["fit-content", "fit-content", "460px"]}
					maxH={["600px"]}
					rounded="lg"
					overflow="hidden"
					justify="center"
					align="center"
					textAlign="center"
					direction="column"
					boxShadow="0px 0px 20px rgba(227, 227, 227, 1)"
					cursor="grab"
				>
					<Flex overflow="hidden" alignItems="flex-start"
						justifyContent='center'
						h={["340px", "340px", "360px"]}
					>
						<Image src={image} object-fit= "cover" h="110%" />
					</Flex>
					<Flex justify="center" alignItems="center" direction="column" m="1rem" w="100%">
						<Text px="2rem" textAlign="center" mb="0rem" fontWeight="700" fontSize="xl">
							{name}
						</Text>
						<Text textAlign="left" px="2rem" mb="2rem" color="gray.500">
							{content}
						</Text>
						{/* Link to the page */}
						<Link to={PROFESSIONAL_CHAPTER_NEWS} style={{ textDecoration: "none" }}>
							<Button variant="ghost" variantColor="blue" border="none">
								LEARN MORE
							</Button>
						</Link>
					</Flex>
				</Flex>
			</Flex>
		</>
	);
};

Card.prototypes = {
	name: PropTypes.string.isRequired,
	content: PropTypes.string.isRequired,
	image: PropTypes.string.isRequired,
};

export default Card;
