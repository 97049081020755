import React, { useState, useEffect } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@chakra-ui/core";
import { addEvent, updateEvent } from "../../slices/eventSlice";

import { GradientDiv } from "../../components/GradientDiv";
import { SecondaryDiv } from "../../components/SecondaryDiv";

const FinalForm = (props) => {
	const { id, data, onClick } = props;
	const history = useHistory();

	const dispatch = useDispatch();
	const { loading } = useSelector((state) => {
		return {
			loading: state.event.loading,
		};
	});

	const handleSubmit = (e) => {
		e.preventDefault();
		console.log(data, "data");
		const submitData = {
			name: data.Name,
			image: data.Image,
			description: data.Description,

			date: {
				from: data.From,
				to: data.To,
			},
			studentChapter: data.studentChapter,
			contact: [
				{
					name: data.ContactName1,
					number: data.ContactPhone1,
				},
				{
					name: data.ContactName2,
					number: data.ContactPhone2,
				},
			],
		};

		if (id === -1) dispatch(addEvent(submitData, history));
		else dispatch(updateEvent(id, { _id: id, ...submitData }, history));
	};

	return (
		<div>
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-between",
					margin: "20px",
					marginTop: 30,
				}}
			>
				<SecondaryDiv borderRadius="5px" width="100px" height="40px" onClick={(e) => onClick(e)}>
					Edit
				</SecondaryDiv>

				<GradientDiv
					borderRadius="5px"
					width="150px"
					height="40px"
					loadingText="Loading..."
					isLoading={loading}
					onClick={(e) => handleSubmit(e)}
				>
					Create/Update
				</GradientDiv>
			</div>
		</div>
	);
};

export default FinalForm;
