import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage, FieldArray } from "formik";
import {
	FormControl,
	Input,
	Text,
	Flex,
	Box,
	Alert,
	Button,
	AlertDescription,
	Select,
	Textarea,
	InputGroup,
	InputLeftAddon,
	InputRightElement,
	Heading,
	FormLabel,
	Divider,
} from "@chakra-ui/core";
import { Container, makeStyles } from "@material-ui/core";
import * as Yup from "yup";
import { Helmet } from "react-helmet";
import {
	MANIPAL_UNIVERSITY,
	UEM_UNIVERSITY,
	AMITY_UNIVERSITY,
	JECRC_UNIVERSITY,
	LNMIIT_UNIVERSITY,
	MNIT_UNIVERSITY,
	POORNIMA_UNIVERSITY,
	MUJ_PROFESSIONAL_CHAPTER,
} from "../../constants/chapterNames";
//styles
import registrationStyles from "../../assets/jss/views/RegistrationStyles";

//components
import { GradientDiv } from "../../components/GradientDiv";

const useStyles = makeStyles(registrationStyles);

const Form1 = (props) => {
	const classes = useStyles();

	const {
		eventName,
		eventImage,
		eventDescription,
		eventFromDate,
		eventToDate,
		studentChapter,

		contactName1,
		contactPhone1,
		contactName2,
		contactPhone2,
	} = props.values;

	console.log(eventFromDate.substring(0, 10));
	const phoneRegExp = /([+]?\d{1,2}[.-\s]?)?(\d{3}[.-]?){2}\d{4}/;
	const chapterNames = [
		MANIPAL_UNIVERSITY,
		UEM_UNIVERSITY,
		AMITY_UNIVERSITY,
		JECRC_UNIVERSITY,
		LNMIIT_UNIVERSITY,
		MNIT_UNIVERSITY,
		POORNIMA_UNIVERSITY,
		MUJ_PROFESSIONAL_CHAPTER,
	];
	const [selectedChapter, setSelectedChapter] = useState(
		studentChapter
			? chapterNames.find((chapter) => chapter.toLowerCase().replace(/ /g, "-") === studentChapter)
			: ""
	);
	// Image upload widget
	const openWidget = (setFieldValue, arrayHelpers, index) => {
		window.cloudinary
			.createUploadWidget(
				{
					cloudName: "dashed",
					uploadPreset: "acmqdu4e",
				},
				(error, result) => {
					if (result.event === "success") {
						arrayHelpers.insert(index, result.info.secure_url);

						setFieldValue(`eventImage.${index}`, result.info.secure_url);
						// eventImage = ;
					}
				}
			)
			.open();
	};

	return (
		<>
			<Container style={{overflow:'hidden'}} maxWidth="sm">
			<Helmet>
				<script
					src="https://widget.Cloudinary.com/v2.0/global/all.js"
					type="text/javascript"
				></script>
			</Helmet>
				<Heading
				textAlign="center"
				size='lg'
				my="3rem"
				>
					Create Event
				</Heading>
				<Flex
					
					alignContent="center"
					justify="space-around"
					// margin=1"auto"
					mb="4rem"
				>
					<Formik
					enableReinitialize
					initialValues={{
						eventName,
						eventImage,
						eventDescription,
						eventFromDate: eventFromDate.substring(0, 10),
						eventToDate: eventToDate.substring(0, 10),
						studentChapter,
						contactName1,
						contactPhone1,
						contactName2, 
						contactPhone2, 
					}}
					validationSchema={Yup.object().shape({
						eventName: Yup.string().required("Event name is required!"),
						eventImage: Yup.array()
							.of(Yup.string().url().required())
							.required()
							.min(1, "At least one image is required"),
						eventDescription: Yup.string().required("Event description is required!"),
						eventFromDate: Yup.date().required("Start date cannot be empty!"),
						eventToDate: Yup.date().required("End date cannot be empty!"),
						studentChapter: Yup.string()
							.oneOf(
								chapterNames.map((chapter) => chapter),
								"Please select an event"
							)
							.required("Chapter Name is required!"),
						contactName1: Yup.string().required("Name cannot be empty"),
						contactPhone1: Yup.string()
							.required("Phone number cannot be empty")
							.matches(phoneRegExp, "Phone number invalid")
							.length(10, "Phone number invalid"),
						contactName2: Yup.string().required("Name cannot be empty"),
						contactPhone2: Yup.string()
							.required("Phone number cannot be empty")
							.matches(phoneRegExp, "Phone number invalid")
							.length(10, "Phone number invalid"),
					})}
					onSubmit={(fields) => {
						console.log(fields);
						props.handleStateChange(fields);
						props.nextStep();
					}}
					render={({ errors, status, touched, setFieldValue, handleChange, values }) => (
						<Form className={classes.form}>
							<Container>
							
							<FormControl  mb="1rem" className={classes.formControl}>
								<>
									<Field  name="eventName" type="text">
										{({ field, form }) => (
											<>
											<FormLabel>Event Name</FormLabel>
													<InputGroup w="100%">
												<Input
													isInvalid={
														form.touched.eventName && form.errors.eventName ? true : false
													}
														{...field}
													
													// roundedLeft="0"
													placeholder="Hackathon"
												/>
											</InputGroup>
											</>
										)}
									</Field>
									<ErrorMessage name="eventName">
										{() => (
											<Alert status="error" variant="subtle" className={classes.alerts}>
												<AlertDescription className={classes.alertDescription}>
													{errors.eventName}
												</AlertDescription>
											</Alert>
										)}
									</ErrorMessage>
								</>
							</FormControl>
							<Flex justifyContent="space-between">
								<FormControl mb="1rem" w="45%" className={classes.formControl}>
									<>
										<Field w="100%" name="eventFromDate" type="date">
											{({ field, form }) => (
												<>
													<FormLabel>Start Date</FormLabel>
												<InputGroup w="100%">
													<Input
														isInvalid={
															form.touched.eventFromDate && form.errors.eventFromDate
																? true
																: false
														}
														{...field}
														
														type="date"
														placeholder="Start date"
													/>
												</InputGroup>
													</>
											)}
										</Field>

										<ErrorMessage name="eventFromDate">
											{() => (
												<Alert status="error" variant="subtle" className={classes.alerts}>
													<AlertDescription className={classes.alertDescription}>
														{errors.eventFromDate}
													</AlertDescription>
												</Alert>
											)}
										</ErrorMessage>
									</>
								</FormControl>

								<FormControl   mb="1rem" w="45%" className={classes.formControl}>
									<>
										<Field w="100%" name="eventToDate" type="date">
											{({ field, form }) => (
												<>
													<FormLabel>End Date</FormLabel>
												<InputGroup w="100%">
													<Input
														isInvalid={
															form.touched.eventToDate && form.errors.eventToDate ? true : false
														}
														{...field}
														// roundedLeft="0"
														type="date"
														placeholder="Start date"
													/>
												</InputGroup>
													</>
											)}
										</Field>

										<ErrorMessage name="eventToDate">
											{() => (
												<Alert status="error" variant="subtle" className={classes.alerts}>
													<AlertDescription className={classes.alertDescription}>
														{errors.eventToDate}
													</AlertDescription>
												</Alert>
											)}
										</ErrorMessage>
									</>
								</FormControl>
							</Flex>
							<FormControl  mb="1rem" className={classes.formControl}>
								<>
									<Field  name="eventDescription" type="text">
										{({ field, form }) => (
											<>
												<FormLabel	rmLabel>Event Description</FormLabel>
												<InputGroup	w="100%">
													<Textarea
													
														isInvalid={
															form.touched.eventDescription && form.errors.eventDescription
																? true
																: false
														}
																{...field}
														w="100%"
														fontFamily="Poppins"
														placeholder="Event Description"
													/>
												</InputGroup>
											</>
													
										)}
									</Field>

									<ErrorMessage name="eventDescription">
										{() => (
											<Alert status="error" variant="subtle" className={classes.alerts}>
												<AlertDescription className={classes.alertDescription}>
													{errors.eventDescription}
												</AlertDescription>
											</Alert>
										)}
									</ErrorMessage>
								</>
							</FormControl>
							
							<FormControl
								 mb="1rem"
								className={classes.formControl}
								onChange={(e) => {
									let f = 0;
									chapterNames.forEach((chapter) => {
										if (e.target.value === chapter) {
											f = 1;
											setSelectedChapter(chapter);
										}
										if (!f) setSelectedChapter(undefined);
									});
								}}
							>
								<div style={{ width: "100%" }}>
									<FormLabel>Select Chapter</FormLabel>
									<Field
										component="select"
										name="studentChapter"
										onChange={(e) => {
											handleChange(e);
										}}
									>
										{({ field, form }) => (
											<Select
												isInvalid={
													form.touched.studentChapter && form.errors.studentChapter
														? true
														: false
												}
												{...field}
											>
												<option>Choose a chapter</option>
												{chapterNames.map((chapter, i) => (
													<option key={i} value={chapter}>
														{chapter}
													</option>
												))}
											</Select>
										)}
									</Field>

									<ErrorMessage name="studentChapter">
										{() => (
											<Alert status="error" variant="subtle" className={classes.alerts}>
												<AlertDescription className={classes.alertDescription}>
													{errors.studentChapter}
												</AlertDescription>
											</Alert>
										)}
									</ErrorMessage>
								</div>
							</FormControl>

							<FormControl  mb="1rem" className={classes.formControl}>
								<>
									{/* <Field name="eventImage" type="text">
										{({ field, form }) => (
											<InputGroup> */}
									<FormLabel>Add Image</FormLabel>
									<FieldArray
										name="eventImage"
										render={(arrayHelpers) => (
											<div>
												{values.eventImage && values.eventImage.length > 0 ? (
													values.eventImage.map((image, index) => (
														<div key={index}>
															{console.log(image)}
															<InputGroup>
																<InputLeftAddon>Event Image URL</InputLeftAddon>
																<Input
																	name={`eventImage.${index}`}
																	value={image}
																	// isInvalid={
																	// errors.touched.eventImage[index] &&
																	// errors.errors.eventImage[index]
																	// 		? true
																	// 		: false
																	// }
																	roundedLeft="0"
																	placeholder="https://picsum.photos/960/960"
																/>
																<InputRightElement width="4.5rem" paddingRight="0.5rem">
																	<Button
																		h="1.75rem"
																		size="sm"
																		onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
																	>
																		-
																	</Button>
																	<Button
																		h="1.75rem"
																		size="sm"
																		onClick={() =>
																			openWidget(setFieldValue, arrayHelpers, index)
																		} // insert an empty string at a position
																	>
																		+
																	</Button>
																</InputRightElement>
															</InputGroup>
															<ErrorMessage name="eventImage">
																{() => (
																	<Alert
																		status="error"
																		variant="subtle"
																		className={classes.alerts}
																	>
																		<AlertDescription className={classes.alertDescription}>
																			{errors.eventImage[index]}
																		</AlertDescription>
																	</Alert>
																)}
															</ErrorMessage>
														</div>
													))
												) : (
														<Button
															variant="solid"
															variantColor="blue"
														// h="1.75rem"
															border="none"
														size="md"
														onClick={() => openWidget(setFieldValue, arrayHelpers, 0)} // insert an empty string at a position
													>
														Add image
													</Button>
												)}
											</div>
										)}
									/>
								</>
							</FormControl>
							
							<Flex  mb="1rem" mt="2rem"alignItems="center" justify="space-between">
								<Text  fontWeight="600">CONTACT DETAILS</Text>
								<Box m="0" bg="gray.300" height="2px" width ={["50%","50%","50%",'70%']}></Box>
							</Flex>
							
							<Flex>
									
												
							<FormControl  mb="1rem" className={classes.formControl}>
								<div>
									<Field name="contactName1" type="text">
											{({ field, form }) => (
										<>
												<FormLabel>Name</FormLabel>
											<InputGroup>
												<Input
													isInvalid={
														form.touched.contactName1 && form.errors.contactName1
															? true
															: false
													}
													{...field}
													placeholder="Full Name"
												/>
											</InputGroup>
										</>
										)}
									</Field>
									<ErrorMessage name="contactName1">
										{() => (
											<Alert status="error" variant="subtle" className={classes.alerts}>
												<AlertDescription className={classes.alertDescription}>
													{errors.contactName1}
												</AlertDescription>
											</Alert>
										)}
									</ErrorMessage>
								</div>
							</FormControl>

							<FormControl ml="1rem" mb="1rem" className={classes.formControl}>
								<div>
									<Field name="contactPhone1" type="tel">
											{({ field, form }) => (
										<>
												<FormLabel>Contact Number</FormLabel>
											<InputGroup>
												<InputLeftAddon children="+91" />
												<Input
													
													isInvalid={
														form.touched.contactPhone1 && form.errors.contactPhone1
															? true
															: false
													}
													{...field}
													type="tel"
													// p="0"
																
														py="0"
													pl="1rem"
													roundedLeft="0" 
													placeholder="XXX-XXX-XXXX"
												/>
											</InputGroup>
										</>
										)}
									</Field>

									<ErrorMessage name="contactPhone1">
										{() => (
											<Alert status="error" variant="subtle" className={classes.alerts}>
												<AlertDescription className={classes.alertDescription}>
													{errors.contactPhone1}
												</AlertDescription>
											</Alert>
										)}
									</ErrorMessage>
								</div>
							</FormControl>
								</Flex>	
								<Flex>
									
							
							<FormControl mb="1rem" className={classes.formControl}>
								<div>
									<Field name="contactName2" type="text">
											{({ field, form }) => (
										<>
												<FormLabel>Name</FormLabel>
											<InputGroup>
												<Input
													isInvalid={
														form.touched.contactName2 && form.errors.contactName2
															? true
															: false
													}
													{...field}
													placeholder="Full Name"
												/>
											</InputGroup>
										</>
										)}
									</Field>
									<ErrorMessage name="contactName2">
										{() => (
											<Alert status="error" variant="subtle" className={classes.alerts}>
												<AlertDescription className={classes.alertDescription}>
													{errors.contactName2}
												</AlertDescription>
											</Alert>
										)}
									</ErrorMessage>
								</div>
							</FormControl>

							<FormControl  ml="1rem" mb="1rem" className={classes.formControl}>
								<div>
									<Field name="contactPhone2" type="tel">
												{({ field, form }) => (
										<>
												<FormLabel>Contact Number</FormLabel>
											<InputGroup>
												<InputLeftAddon children="+91" />
												<Input
													
													isInvalid={
														form.touched.contactPhone2 && form.errors.contactPhone2
															? true
															: false
													}
													{...field}
													type="tel"
													py="0"
													pl="1rem"
													roundedLeft="0" 
													placeholder="XXX-XXX-XXXX"
												/>
											</InputGroup>
										</>
										)}
									</Field>

									<ErrorMessage name="contactPhone2">
										{() => (
											<Alert status="error" variant="subtle" className={classes.alerts}>
												<AlertDescription className={classes.alertDescription}>
													{errors.contactPhone2}
												</AlertDescription>
											</Alert>
										)}
									</ErrorMessage>
								</div>
							</FormControl>
							</Flex>					
							<Box
							display= "flex"
							justifyContent= "flex-end"
									 mb="1rem"
							>
								<GradientDiv
									borderRadius="5px"
									width="150px"
									height="40px"
									onClick={() => {
										document.forms[0].requestSubmit();
									}}
								>
									Continue 
								</GradientDiv>
							</Box>
								
							</Container>
						</Form>
					)}
				/>
					</Flex>
			</Container>
		</>
	);
};

export default Form1;
