import { combineReducers } from 'redux';
import eventReducer from './eventSlice';
import authReducer from './authSlice';
import alertReducer from './alertSlice';

export default combineReducers({
  auth: authReducer,
  alert: alertReducer,
  event: eventReducer,
});
