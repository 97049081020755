import * as React from "react";
import Particles from "react-particles-js";

const BubbleParticle = ({ height }) => {
	return (
		<>
			<Particles
				width="100%"
				height={height}
				style={{ position: "absolute", top: "0", zIndex: "10" }}
				params={{
					particles: {
						shape: {
							polygon: {
								nb_sides: 4,
							},
							type: "polygon",
						},
						color: {
							value: "#0b46a3",
						},
						number: {
							value: 100,
							density: {
								enable: true,
								value_area: 700,
							},
						},
						size: {
							value: 7,
							random: true,
							anim: {
								speed: 50,
								size_min: 2,
							},
						},
						line_linked: {
							enable: false,
						},
						move: {
							random: true,
							speed: 1,
							direction: "right",
							out_mode: "out",
							bounce: true,
						},
					},
					interactivity: {
						events: {
							onhover: {
								enable: true,
								mode: "bubble",
							},
							onclick: {
								enable: true,
								mode: "repulse",
							},
							resize: true,
						},
						modes: {
							grab: {
								distance: 150,
								line_linked: {
									opacity: 1,
								},
							},
							bubble: {
								distance: 150,
								duration: 2,
								size: 0,
								opacity: 0,
								speed: 5,
							},
							repulse: {
								distance: 200,
								duration: 0.5,
							},
						},
					},
				}}
			/>
		</>
	);
};

export default BubbleParticle;
