import API from "./api";

export const register = async (formData) => {
	try {
		const res = await API.post("/auth/register", formData);
		return res.data;
	} catch (err) {
		throw err;
	}
};

export const login = async (formData) => {
	try {
		const res = await API.post("/auth/login", formData);
		return res.data;
	} catch (err) {
		throw err;
	}
};

export const getMe = async (options = {}) => {
	try {
		const res = await API.get("/auth/getUser");
		return res.data;
	} catch (err) {
		throw err;
	}
};

export const updateDetails = async (formData) => {
	try {
		const res = await API.put("/auth/updateDetails", formData);
		return res.data;
	} catch (err) {
		throw err;
	}
};

export const updatePassword = async (formData) => {
	try {
		const res = await API.put("/auth/updatePassword", formData);
		return res.data;
	} catch (err) {
		throw err;
	}
};

export const logout = async () => {
	try {
		const res = await API.get("/auth/logout");
		return res.data;
	} catch (err) {
		throw err;
	}
};

export const forgotPassword = async (email) => {
	try {
		const res = await API.post("/auth/forgotPassword", { email });
		return res.data;
	} catch (err) {
		throw err;
	}
};

export const resetPassword = async (token, password) => {
	try {
		const res = await API.put(`/auth/resetpassword/${token}`, { password });
		return res.data;
	} catch (err) {
		throw err;
	}
};
