import axios from "axios";

const BASE_URL = "https://us-central1-acm-professional-chapter.cloudfunctions.net/app/api/v1";
// const BASE_URL = "http://localhost:5000/acm-professional-chapter/us-central1/app/api/v1";

const API = axios.create({
	baseURL: BASE_URL,
	headers: {
		"Content-Type": "application/json",
	},
});

export default API;
