import React from "react";
import { Container, Typography } from "@material-ui/core";
import { Flex, Text, Link as LinkChakra } from "@chakra-ui/core";

//logo
import ACM_LOGO from "../../assets/images/acm3.png";

//icons
import CallIcon from '@material-ui/icons/Call';
import EmailIcon from '@material-ui/icons/Email';
import LocationOnIcon from '@material-ui/icons/LocationOn';

//Routes
import {
	HOME,
	OFFICERS,
	MEMBERSHIPS,
	STUDENT_CHAPTERS,
	PROFESSIONAL_CHAPTER_NEWS,
	NEWS,
	AWARDS_OF_APPRECIATION,
	CONTACT,
} from "../../constants/routes";

//Styles
import { makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import FooterStyles from "../../assets/jss/components/FooterStyles";
const useStyles = makeStyles(FooterStyles);

const Footer = () => {
	const classes = useStyles();
	return (
		<div>
			<div className={classes.footer}>
				{/* LOGO */}
				<Container>
					<Flex mb="2rem" alignItems="center">
						<img src={ACM_LOGO} className={classes.logo} alt="" />
						{/* <Heading ml="1rem" display="inline" size="md">
							ACM JAIPUR CHAPTER
						</Heading> */}
					</Flex>
				</Container>

				<Container className={classes.footerContainer}>
					<div>
						{/* <Typography className={classes.slogan} variant="h5">
							Connecting tech with people,
							<br /> passion and possibilities.
						</Typography> */}
						<Typography className={classes.headings}>Links</Typography>
						<Flex>
							<Flex direction="column">
								<Link className={classes.link} to={HOME}>
									Home
								</Link>
								<Link className={classes.link} to={OFFICERS}>
									Officers
								</Link>
								<Link className={classes.link} to={MEMBERSHIPS}>
									Membership
								</Link>
								<Link className={classes.link} to={STUDENT_CHAPTERS}>
									Student Chapters
								</Link>
								<Link className={classes.link} to={PROFESSIONAL_CHAPTER_NEWS}>
									Professional Chapters
								</Link>
							</Flex>
							<Flex ml="2rem" direction="column">
								<Link className={classes.link} to={NEWS}>
									News
								</Link>
								<Link className={classes.link} to={AWARDS_OF_APPRECIATION}>
									Awards
								</Link>

								<Link className={classes.link} to={CONTACT}>
									Contact
								</Link>
							</Flex>
						</Flex>
						<Flex display={["none","none","none","block"]} fontWeight="bold" mt="1.5rem">
							ACM Professional Chapter Jaipur © 2020
						</Flex>
					</div>

					<div className={classes.footerSection}>
						<Text mt={["1rem","1rem","1rem","0"]} className={classes.headings}>Contact Us</Text>
						<Typography>
							<Flex align="center">
								<CallIcon fontSize="small"/>
								<LinkChakra ml="0.5rem"  className={classes.link} href="tel:919772106364">
									+91 75059 92320
								</LinkChakra>
							</Flex>
							<Flex align="center">
								<EmailIcon fontSize="small"/>
								<LinkChakra ml="0.5rem" className={classes.link} href="mailto:acm-schap@muj.manipal.edu">
									acm-schap@muj.manipal.edu
								</LinkChakra>
							</Flex>
						</Typography>
					</div>
					<div className={classes.footerSection}>
						<Flex align="center"  className={classes.headings}>
							<LocationOnIcon fontSize="small"/>
							<Text m="0" mr="0.5rem">Address</Text>
						</Flex>
						<Typography>
							
							<a
								className={classes.link}
								href="https://www.google.com/maps/place/Manipal+University+Jaipur/@26.8438552,75.5630456,17z/data=!3m1!4b1!4m5!3m4!1s0x396c4850e05bee9b:0x1b8d67402d4eb863!8m2!3d26.8438552!4d75.5652343"
								rel="noopener noreferrer"
								target="_BLANK"
							>
								Manipal University Jaipur,
								<br />
								Dehmi Kalan, Near GVK Toll Plaza,
								<br />
								Jaipur-Ajmer Expressway, Jaipur,
								<br />
								Rajasthan 303007
							</a>
						</Typography>
					</div>
					<Flex display={["block","block","block","none"]} fontWeight="bold" mt="1.5rem">
							ACM Professional Chapter Jaipur © 2020
					</Flex>
				</Container>
			</div>
		</div>
	);
};

export default Footer;
