const registrationStyles = (theme) => ({
	root: {
		"& > * ": {
			marginTop: theme.spacing(4),
			width: "25ch",
			zIndex: 200,
		},
	},
	form: {
		// minWidth:"100%",
		// margin: 15,
		// marginRight: 25,
		// marginLeft: 25,
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-start",
		// jusifyContent: "center",
		// width: "50%",
	},
	formControl: {
		display: "flex",
		alignItems: "flex-start",
		flexDirection: "column",
		// jusifyContent: "center",
		// minWidth: "100%",
		// width: "100%",
	},
	label: {
		color: "#AAAAAA",
		// width: 140,
		textAlign: "center",
	},
	alerts: {
		padding: 8,
		borderRadius: 5,
	},
	alertDescription: {
		fontSize: 12,
		color: "black",
	},
});
export default registrationStyles;
