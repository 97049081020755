import React, { lazy, Suspense } from "react";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
// import { ThemeProvider } from "@material-ui/core";
import { ThemeProvider } from "@chakra-ui/core";
// import { MUItheme } from "./theme";
import { ChakraUItheme } from "./theme";
import * as ROUTES from "./constants/routes";
import Loader from "./components/Loader/Loader";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import Alert from "./components/Alert";
import { Provider } from "react-redux";
import store from "./store";
import Login from "./views/auth/Login/Login";
import PrivateRoute from "./components/PrivateRoute";
import AdminDashboard from "./views/Dashboard/Admin/AdminDashboard";
import CreateEvent from "./views/CreateEvent/CreateEvent";
import { EventCarousel } from "./views/Home/EventCarousel";
import NotFound from "./components/NotFound";

const AwardsOfAppreciation = lazy(() =>
	import("./views/AwardsOfAppreciation/AwardsOfAppreciation")
);
const Contact = lazy(() => import("./views/Contact/Contact"));
const Home = lazy(() => import("./views/Home/Home"));
const Memberships = lazy(() => import("./views/Memberships/Memberships"));
const News = lazy(() => import("./views/News/News.jsx"));
const Officers = lazy(() => import("./views/Officers/Officers"));
const ProfessionalChapterNews = lazy(() =>
	import("./views/ProfessionalChapterNews/ProfessionalChapterNews")
);
const StudentChapters = lazy(() => import("./views/StudentChapters/StudentChapters"));

// Student Chapters
const AmityUniversity = lazy(() =>
	import("./views/StudentChapters/AmityUniversity/AmityUniversity")
);
const JECRCUniversity = lazy(() =>
	import("./views/StudentChapters/JECRCUniversity/JECRCUniversity")
);
const LNMIITUniversity = lazy(() =>
	import("./views/StudentChapters/LNMIITUniversity/LNMIITUniversity")
);
const ManipalUniversity = lazy(() =>
	import("./views/StudentChapters/ManipalUniversity/ManipalUniversity")
);
const MNITUniversity = lazy(() => import("./views/StudentChapters/MNITUniversity/MNITUniversity"));
const PoornimaUniversity = lazy(() =>
	import("./views/StudentChapters/PoornimaUniversity/PoornimaUniversity")
);
const UEMUniversity = lazy(() => import("./views/StudentChapters/UEMUniversity/UEMUniversity"));

function App() {
	return (
		<Provider store={store}>
			<ThemeProvider theme={ChakraUItheme}>
				<Router>
					<div className="App">
						<Navbar />
						<Alert />
						<Suspense fallback={<Loader />}>
							<Switch>
								<Route exact path={ROUTES.HOME}>
									<Home />
									<Suspense fallback={<div></div>}>
										<EventCarousel />
									</Suspense>
								</Route>
								<Route exact path={ROUTES.LOGIN}>
									<Login />
								</Route>
								<Route exact path={ROUTES.AWARDS_OF_APPRECIATION}>
									<AwardsOfAppreciation />
								</Route>
								<Route exact path={ROUTES.PROFESSIONAL_CHAPTER_NEWS}>
									<ProfessionalChapterNews />
								</Route>
								<Route exact path={ROUTES.OFFICERS}>
									<Officers />
								</Route>
								<Route exact path={ROUTES.STUDENT_CHAPTERS}>
									<StudentChapters />
								</Route>
								<Route exact path={ROUTES.NEWS}>
									<News />
								</Route>
								<Route exact path={ROUTES.MEMBERSHIPS}>
									<Memberships />
								</Route>

								<Route exact path={ROUTES.CONTACT}>
									<Contact />
								</Route>
								<Route exact path={ROUTES.AMITY}>
									<AmityUniversity />
								</Route>
								<Route exact path={ROUTES.JECRC}>
									<JECRCUniversity />
								</Route>
								<Route exact path={ROUTES.LNMIIT}>
									<LNMIITUniversity />
								</Route>
								<Route exact path={ROUTES.MANIPAL}>
									<ManipalUniversity />
								</Route>
								<Route exact path={ROUTES.MNIT}>
									<MNITUniversity />
								</Route>
								<Route exact path={ROUTES.POORNIMA}>
									<PoornimaUniversity />
								</Route>
								<Route exact path={ROUTES.UEM}>
									<UEMUniversity />
								</Route>
								<PrivateRoute exact path={ROUTES.ADMIN_DASHBOARD}>
									<AdminDashboard />
								</PrivateRoute>
								<PrivateRoute exact path={ROUTES.CREATE_EVENT}>
									<CreateEvent />
								</PrivateRoute>
								<PrivateRoute exact path={ROUTES.EDIT_EVENT}>
									<CreateEvent />
								</PrivateRoute>
								<Route exact path="*">
									<NotFound />
								</Route>
							</Switch>
						</Suspense>
						<Footer />
					</div>
				</Router>
			</ThemeProvider>
		</Provider>
	);
}

export default App;
