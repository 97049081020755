import { createMuiTheme } from "@material-ui/core/styles";
import { theme } from "@chakra-ui/core";

export const MUItheme = createMuiTheme({
	palette: {
		primary: {
			main: "#0f56c7",
		},
		secondary: {
			light: "#ff7961",
			main: "#f44336",
			dark: "#ba000d",
			contrastText: "#000",
		},
	},
	typography: {
		fontFamily: ["Poppins", "sans-serif"].join(","),
		body1: {
			fontFamily: ["Poppins", "sans-serif"].join(","),
		},
	},
});

export const ChakraUItheme = {
	...theme,
	fonts: {
		heading: "Poppins, sans-serif",
		body: "Poppins, sans-serif",
		mono: "monospace",
	},
	colors: {
		...theme.colors,
		brand: {
			main: "#0f56c7",
		},
	},
};
