import React from "react";
import { Box, Fade, makeStyles, withStyles } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Link, NavLink } from "react-router-dom";

import { Flex, Text } from "@chakra-ui/core";

//student chapters
import {
	STUDENT_CHAPTERS,
	MANIPAL,
	AMITY,
	UEM,
	LNMIIT,
	MNIT,
	JECRC,
	POORNIMA,
} from "../../constants/routes";
import NavbarStyles from "../../assets/jss/components/NavbarStyles";
import {
	AMITY_UNIVERSITY,
	LNMIIT_UNIVERSITY,
	MANIPAL_UNIVERSITY,
	UEM_UNIVERSITY,
	MNIT_UNIVERSITY,
	JECRC_UNIVERSITY,
	POORNIMA_UNIVERSITY,
} from "../../constants/chapterNames";
const useStyles = makeStyles(NavbarStyles);

const StyledMenu = withStyles((theme) => ({
	paper: {
		// border: "1px solid #d3d4d5",
		backgroundColor: theme.palette.primary.main,
		boxShadow: "10px 10px 39px -15px rgba(0,0,0,0.75)",
		borderRadius: "15px",
		margin: "0",
	},
}))((props) => (
	<Menu
		elevation={0}
		getContentAnchorEl={null}
		anchorOrigin={{
			vertical: "bottom",
			horizontal: "center",
		}}
		transformOrigin={{
			vertical: "top",
			horizontal: "center",
		}}
		TransitionComponent={Fade}
		{...props}
	/>
));

const StyledMenuItem = withStyles((theme) => ({
	root: {
		color: "white",
		"&:focus": {
			// backgroundColor: theme.palette.primary.main,
			color: "white",
			"& .MuiListItemIcon-root, & .MuiListItemText-primary": {
				color: theme.palette.primary.main,
			},
		},
	},
}))(MenuItem);

export const StudentChapterMenu = () => {
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<Box>
			{/* className={classes.links} added the under line on hover */}
			<Flex
				position="relative"
				mr="1rem"
				aria-controls="student-chapter-menu"
				aria-haspopup="true"
				onMouseEnter={handleClick}
				// onMouseLeave={handleClose}
				className={classes.links}
			>
				{/* pointerEvents="none" because i didn't want the navlink to redirect to a different page,
					but NavLink was needed to have the activeClassName  */}
				<Text pointerEvents="none" textDecoration="none" cursor="pointer" m="0rem">
					<NavLink
						className={classes.link}
						to={STUDENT_CHAPTERS}
						activeClassName={classes.activeNavlink}
					>
						Student Chapters
					</NavLink>
				</Text>
				<ExpandMoreIcon />
			</Flex>

			<StyledMenu
				// getContentAnchorEl={null}
				// anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
				// transformOrigin={{ vertical: "top", horizontal: "center" }}
				// id="simple-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				<StyledMenuItem onClick={handleClose}>
					<Link exact={true} className={classes.link} to={STUDENT_CHAPTERS}>
						<Text fontWeight="bold">View All</Text>
					</Link>
				</StyledMenuItem>
				<StyledMenuItem onClick={handleClose}>
					<Link className={classes.link} to={MANIPAL}>
						{MANIPAL_UNIVERSITY}
					</Link>
				</StyledMenuItem>
				<StyledMenuItem onClick={handleClose}>
					<Link className={classes.link} to={AMITY}>
						{AMITY_UNIVERSITY}
					</Link>
				</StyledMenuItem>
				<StyledMenuItem onClick={handleClose}>
					<Link className={classes.link} to={UEM}>
						{UEM_UNIVERSITY}
					</Link>
				</StyledMenuItem>
				<StyledMenuItem onClick={handleClose}>
					<Link className={classes.link} to={LNMIIT}>
						{LNMIIT_UNIVERSITY}
					</Link>
				</StyledMenuItem>
				<StyledMenuItem onClick={handleClose}>
					<Link className={classes.link} to={MNIT}>
						{MNIT_UNIVERSITY}
					</Link>
				</StyledMenuItem>
				<StyledMenuItem onClick={handleClose}>
					<Link className={classes.link} to={JECRC}>
						{JECRC_UNIVERSITY}
					</Link>
				</StyledMenuItem>
				<StyledMenuItem onClick={handleClose}>
					<Link className={classes.link} to={POORNIMA}>
						{POORNIMA_UNIVERSITY}
					</Link>
				</StyledMenuItem>
			</StyledMenu>
		</Box>
	);
};

export const StudentChapterMenuMobile = () => {
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<Box>
			{/* className={classes.links} added the under line on hover */}
			<Flex
				position="relative"
				ml="2rem"
				my="1rem"
				aria-controls="student-chapter-menu"
				aria-haspopup="true"
				onClick={handleClick}
				// onMouseLeave={handleClose}
				className={classes.drawerLinks}
			>
				{/* pointerEvents="none" because i didn't want the navlink to redirect to a different page,
					but NavLink was needed to have the activeClassName  */}
				<Text pointerEvents="none" textDecoration="none" cursor="pointer" m="0rem">
					<NavLink className={classes.link} to={STUDENT_CHAPTERS}>
						Student Chapters
					</NavLink>
				</Text>
				<ExpandMoreIcon />
			</Flex>

			<StyledMenu
				// getContentAnchorEl={null}
				// anchorOrigin={{ vertical: "top", horizontal: "center" }}
				// transformOrigin={{ vertical: "top", horizontal: "center" }}
				// id="simple-menu"
				style={{ height: 400, overFlow: "scroll", overflowWrap: "break-word" }}
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				<StyledMenuItem onClick={handleClose}>
					<Link exact={true} className={classes.link} to={STUDENT_CHAPTERS}>
						<Text fontWeight="bold">View All</Text>
					</Link>
				</StyledMenuItem>
				<StyledMenuItem onClick={handleClose}>
					<Link className={classes.link} to={MANIPAL}>
						{MANIPAL_UNIVERSITY}
					</Link>
				</StyledMenuItem>
				<StyledMenuItem onClick={handleClose}>
					<Link className={classes.link} to={AMITY}>
						{AMITY_UNIVERSITY}
					</Link>
				</StyledMenuItem>
				<StyledMenuItem onClick={handleClose}>
					<Link className={classes.link} to={UEM}>
						{UEM_UNIVERSITY.split(" ").splice(0, 5).join(" ")}
					</Link>
				</StyledMenuItem>
				<StyledMenuItem onClick={handleClose}>
					<Link className={classes.link} to={LNMIIT}>
						{LNMIIT_UNIVERSITY}
					</Link>
				</StyledMenuItem>
				<StyledMenuItem onClick={handleClose}>
					<Link className={classes.link} to={MNIT}>
						{MNIT_UNIVERSITY.split(" ").splice(0, 5).join(" ")}
					</Link>
				</StyledMenuItem>
				<StyledMenuItem onClick={handleClose}>
					<Link className={classes.link} to={JECRC}>
						{JECRC_UNIVERSITY}
					</Link>
				</StyledMenuItem>
				<StyledMenuItem onClick={handleClose}>
					<Link className={classes.link} to={POORNIMA}>
						{POORNIMA_UNIVERSITY}
					</Link>
				</StyledMenuItem>
			</StyledMenu>
		</Box>
	);
};
