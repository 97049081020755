import React from "react";
import { makeStyles } from "@material-ui/core";

const styles = makeStyles((theme) => ({
	box: {
		cursor: "pointer",
		borderRadius: "10px 10px 10px 10px",
		background: "linear-gradient(94deg, rgba(65, 134, 249, 1) 0%, rgba(1, 131, 232, 1) 100%)",
		boxShadow: "0px 3px 20px rgba(0, 32, 255, 0.2)",
		color: "white",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		fontWeight: "bold",
		transition: "0.3s ease",
		"&:hover": {
			transform: "scale(1.02)",
			boxShadow: "0px 3px 20px rgba(0, 32, 255, 0.5)",
		},
	},
	box_disabled: {
		cursor: "not-allowed",
		borderRadius: "10px 10px 10px 10px",
		background: "linear-gradient(94deg, rgba(240, 240, 240, 1) 0%, rgba(240, 240, 240, 1) 100%)",
		paddingLeft: "8px",
		paddingRight: "8px",
		color: "black",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		fontWeight: "bold",
		transition: "0.3s ease",
	},
}));

export const GradientDiv = ({ onClick, isLoading, loadingText, ...props }) => {
	const classes = styles();
	return (
		<div
			style={{
				borderRadius: props.borderRadius,
				fontSize: props.fontsize,
				margin: props.margin,
				padding: props.padding,
				width: props.width,
				height: props.height,
			}}
			className={isLoading ? classes.box_disabled : classes.box}
			onClick={isLoading ? null : onClick}
		>
			{isLoading ? (loadingText ? loadingText : props.children) : props.children}
		</div>
	);
};
