import React, { useState } from "react";
import { SwipeableDrawer, List, IconButton, Divider, withStyles, Hidden } from "@material-ui/core";

import { Link } from "react-router-dom";

//icon for mobile drawer
import MenuIcon from "@material-ui/icons/Menu";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
//Routes
import {
	HOME,
	OFFICERS,
	MEMBERSHIPS,
	STUDENT_CHAPTERS,
	PROFESSIONAL_CHAPTER_NEWS,
	NEWS,
	AWARDS_OF_APPRECIATION,
	CONTACT,
	ADMIN_DASHBOARD,
} from "../../constants/routes";

//components
import { NavbarMobileLink } from "./NavbarLink";
import { StudentChapterMenuMobile } from "./StudentChapterMenu";
//Styles
import { makeStyles } from "@material-ui/core";
import NavbarStyles from "../../assets/jss/components/NavbarStyles";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { logout } from "../../slices/authSlice";
// const useStyles = makeStyles(NavbarStyles);

const NavbarMobile = (props) => {
	// const classes = useStyles();
	const { classes } = props;
	const [state, setState] = useState(false);
	const {
		auth: { isAuthenticated },
	} = useSelector((state) => {
		return {
			auth: state.auth,
		};
	}, shallowEqual);
	const dispatch = useDispatch();

	const toggleDrawer = (anchor, open) => (event) => {
		setState(!state);
	};
	return (
		<div>
			<IconButton onClick={toggleDrawer()}>
				<MenuIcon style={{ color: "white" }} />
			</IconButton>
			{/* <Hidden mdUp> */}
			<SwipeableDrawer
				classes={{
					paper: classes.paper,
				}}
				className={classes.drawer}
				anchor="right"
				open={state}
				onOpen={toggleDrawer()}
				onClose={toggleDrawer()}
			>
				<div className={classes.drawerBackBtn}>
					<IconButton onClick={toggleDrawer()}>
						<ArrowForwardIosIcon style={{ color: "white" }} />
					</IconButton>
				</div>

				{/* <Divider /> */}
				<List>
					{!isAuthenticated ? (
						<>
							{" "}
							<NavbarMobileLink to={HOME} text="Home" />
							<NavbarMobileLink to={OFFICERS} text="Officers" />
							<NavbarMobileLink to={MEMBERSHIPS} text="Membership" />
							{/* <NavbarMobileLink to={STUDENT_CHAPTERS} text="Student Chapters" /> */}
							<StudentChapterMenuMobile />
							<NavbarMobileLink to={PROFESSIONAL_CHAPTER_NEWS} text="Professional Chapters" />
							<NavbarMobileLink to={NEWS} text="News" />
							<NavbarMobileLink to={AWARDS_OF_APPRECIATION} text="Awards" />
							<NavbarMobileLink to={CONTACT} text="Contact" />
						</>
					) : (
						<>
							<NavbarMobileLink to={ADMIN_DASHBOARD} text="Dashboard" />
							<div onClick={() => dispatch(logout())}>
								<NavbarMobileLink to={HOME} text="Logout" />
							</div>
						</>
					)}
				</List>
			</SwipeableDrawer>
			{/* </Hidden> */}
		</div>
	);
};

export default withStyles(NavbarStyles)(NavbarMobile);
