import React from "react";
import { AppBar, Toolbar, Box, Container } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Typography } from "@material-ui/core";
import { makeStyles, ThemeProvider } from "@material-ui/core";
import Particles from "react-particles-js";
import ACM_LOGO from "../../assets/images/acm3.png";

//material theme
import { MUItheme } from "../../theme";

//mobile nav drawer
import NavbarMobile from "./NavbarMobile";

//components
import { NavbarLink } from "./NavbarLink";

//Student chapter menu
import { StudentChapterMenu } from "./StudentChapterMenu";
//Routes
import {
	HOME,
	OFFICERS,
	MEMBERSHIPS,
	STUDENT_CHAPTERS,
	PROFESSIONAL_CHAPTER_NEWS,
	NEWS,
	AWARDS_OF_APPRECIATION,
	CONTACT,
	ADMIN_DASHBOARD,
} from "../../constants/routes";

//Styles
import NavbarStyles from "../../assets/jss/components/NavbarStyles";
import BubbleParticle from "../Header/BubbleParticle";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { logout } from "../../slices/authSlice";
const useStyles = makeStyles(NavbarStyles);

const Navbar = (props) => {
	const classes = useStyles();
	const {
		auth: { isAuthenticated },
	} = useSelector((state) => {
		return {
			auth: state.auth,
		};
	}, shallowEqual);
	const dispatch = useDispatch();
	return (
		<ThemeProvider theme={MUItheme}>
			<div>
				<AppBar position="static" style={{ boxShadow: "none" }}>
					<BubbleParticle height={"90px"} />

					<div style={{ position: "relative", top: "0", left: "0", zIndex: "200" }}>
						<Container>
							<Toolbar>
								<Box
									className={classes.nav}
									display="flex"
									flexDirection="row"
									justifyContent="space-between"
									alignItems="center"
								>
									<div
										style={{
											display: "flex",
											flexDirection: "row",
											alignItems: "center",
										}}
									>
										<img src={ACM_LOGO} width="60" alt="acm-logo" style={{ padding: "1rem" }} />
										<Link to={HOME} className={classes.title}>
											<Typography className={classes.titleText}>ACM JAIPUR</Typography>
										</Link>
									</div>
									{/* On Desktop */}
									<Box display="flex" flexDirection="row" className={classes.desktopNav}>
										{!isAuthenticated ? (
											<>
												<NavbarLink to={HOME} text="Home" />
												<NavbarLink to={OFFICERS} text="Officers" />
												<NavbarLink to={MEMBERSHIPS} text="Membership" />
												<StudentChapterMenu className={classes.links} />
												{/* <NavbarLink to={STUDENT_CHAPTERS} text="Student Chapters" /> */}
												<NavbarLink to={PROFESSIONAL_CHAPTER_NEWS} text="Professional Chapters" />
												<NavbarLink to={NEWS} text="News" />
												<NavbarLink to={AWARDS_OF_APPRECIATION} text="Awards" />

												<NavbarLink to={CONTACT} text="Contact" />
											</>
										) : (
											<>
												<NavbarLink to={ADMIN_DASHBOARD} text="Dashboard" />
												<div onClick={() => dispatch(logout())}>
													<NavbarLink to={HOME} text="Logout" />
												</div>
											</>
										)}
									</Box>
									{/* On Mobile Devices */}
									<Box className={classes.mobileNav}>
										<NavbarMobile />
									</Box>
								</Box>
							</Toolbar>
						</Container>
					</div>
				</AppBar>
			</div>
		</ThemeProvider>
	);
};

export default Navbar;
