import React from "react";
import {
	Text,
	Flex,
	Box,
	useColorMode,
	List,
	ListItem,
	SimpleGrid,
	Stat,
	StatLabel,
	StatNumber,
	Divider,
} from "@chakra-ui/core";

import { makeStyles } from "@material-ui/core";

//styles
import registrationStyles from "../../assets/jss/views/RegistrationStyles";

//components
import FinalForm from "./FinalForm";

const useStyles = makeStyles(registrationStyles);

const Form3 = (props) => {
	const back = (e) => {
		e.preventDefault();
		props.prevStep();
	};

	const {
		id,
		eventName,
		eventImage,
		eventDescription,
		eventFromDate,
		eventToDate,
		studentChapter,
		contactName1,
		contactPhone1,
		contactName2,
		contactPhone2,
	} = props.values;

	const data1 = {
		Name: eventName,
		Description: eventDescription,
		From: eventFromDate,
		To: eventToDate,
		studentChapter: studentChapter,
		Image: eventImage,
	};
	const data2 = {
		ContactName1: contactName1,
		ContactPhone1: contactPhone1,
		ContactName2: contactName2,
		ContactPhone2: contactPhone2,
	};

	return (
		<>
			<Flex
				alignContent="center"
				justify="space-around"
				margin="auto"
				mt={62}
				p={[0, 2, 10, 20]}
				w={["100%", "90%", "60%", "50%"]}
			>
				<Box
					w="100%"
					borderRadius={10}
					// boxShadow="md"
					backgroundColor="white"
				>
					<Box>
						<Box>
							<Text
								fontSize="3xl"
								fontWeight="bold"
								m={[5, 10, 20, 20]}
								mt={[5, 5, 5, 5]}
								mb={[5, 5, 5, 5]}
							>
								Event
							</Text>
						</Box>
						<Box p={[0, 3, 10, 30]}>
							<SimpleGrid minChildWidth="150px" spacing="20px" columns={2} m={4}>
								<Flex flexDirection="column" justifyContent="flex-start">
									{Object.entries(data1).map((item) => (
										<Stat>
											<StatLabel style={{ color: "black", fontSize: "16px" }}>{item[0]}</StatLabel>
											<StatNumber
												fontSize={20}
												style={{ color: "#898b8c", wordBreak: "break-word" }}
											>
												{item[1]}
											</StatNumber>
											<Divider />
										</Stat>
									))}
								</Flex>
								<Flex flexDirection="column" justifyContent="flex-start">
									{Object.entries(data2).map((item) => (
										<Stat>
											<StatLabel style={{ color: "black", fontSize: "16px" }}>{item[0]}</StatLabel>
											<StatNumber fontSize={20} style={{ color: "#898b8c" }}>
												{item[1]}
											</StatNumber>
											<Divider />
										</Stat>
									))}
								</Flex>
							</SimpleGrid>

							<FinalForm id={id} data={{ ...data1, ...data2 }} onClick={(e) => back(e)} />
						</Box>
					</Box>
				</Box>
			</Flex>
		</>
	);
};

export default Form3;
