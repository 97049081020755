import * as REQUESTS from "../api/eventRequests";
import { createSlice } from "@reduxjs/toolkit";
import { setAlert } from "./alertSlice";
import { useState } from "react";
import { ADMIN_DASHBOARD } from "../constants/routes";
// import { EVENTS, EVENT_PAGE, ADMIN_DASHBOARD, ADMIN_EVENTS } from "../constants/routes";
// import { eventsArray } from "../constants/events";

const initialState = {
	events: null,
	studentChapterEvents: null,
	event: null,
	addedEvent: null,
	updatedEvent: null,
	deletedEvent: null,
	featuredEvent: null,
	loading: false,
	isOffline: false,
};

const eventSlice = createSlice({
	name: "event",
	initialState,
	reducers: {
		setEvents(state, action) {
			state.events = action.payload;
			state.loading = false;
		},
		setEvent(state, action) {
			state.event = action.payload;
			// state.event = state.events.filter((event) => {
			// 	if (event.slug === action.payload) {
			// 		return event;
			// 	}
			// 	// return event;
			// });
			state.loading = false;
		},
		setStudentChapterEvents(state, action) {
			state.studentChapterEvents = action.payload;
			state.loading = false;
		},
		setAddedEvent(state, action) {
			state.addedEvent = action.payload;
			state.events = [...state.events, action.payload];
			state.loading = false;
		},
		setUpdatedEvent(state, action) {
			state.updatedEvent = action.payload;
			state.events = state.events.map((event) => {
				if (event._id === action.payload._id) return action.payload;
				return event;
			});
			state.loading = false;
		},
		setFeaturedEvent(state, action) {
			state.featuredEvent = action.payload;
			state.loading = false;
		},
		setDeletedEvent(state, action) {
			state.deletedEvent = action.payload;
			state.events = state.events.filter((event) => event._id !== action.payload._id);
			state.loading = false;
		},
		setLoading(state) {
			state.loading = !state.loading;
		},
		setIsOffline(state) {
			state.isOffline = !state.isOffline;
		},
	},
});

export const {
	setDeletedEvent,
	setEvent,
	setEvents,
	setLoading,
	setUpdatedEvent,
	setAddedEvent,
	setFeaturedEvent,
	setIsOffline,
	setStudentChapterEvents,
} = eventSlice.actions;

export default eventSlice.reducer;

// Thunks

// get all events
export const getAllEvents = () => async (dispatch) => {
	try {
		dispatch(setLoading());
		const data = await REQUESTS.getAllEvents();
		dispatch(setEvents(data));
	} catch (err) {
		console.log(err);
		dispatch(setAlert(err.message, "error"));
	}
};

// get all events for student chapter
export const getAllEventsForStudentChapter = (studentChapter) => async (dispatch) => {
	const updatedStudentChapter = studentChapter.toLowerCase().replace(/ /g, "-");
	console.log(updatedStudentChapter);
	try {
		dispatch(setLoading());
		const data = await REQUESTS.getAllEventsForStudentChapter(updatedStudentChapter);
		dispatch(setStudentChapterEvents(data));
	} catch (err) {
		console.log(err);
		dispatch(setAlert(err.message, "error"));
	}
};

// get event for event page by id/name
export const getEvent = (slug) => async (dispatch) => {
	try {
		dispatch(setLoading());
		const data = await REQUESTS.getEventBySlug(slug);
		dispatch(setEvent(data));
		// dispatch(setEvent(slug));
	} catch (err) {
		console.log(err);
		dispatch(setAlert(err.message, "error"));
	}
};

// get event featured event
export const getFeaturedEvent = () => async (dispatch) => {
	try {
		dispatch(setLoading());
		const data = await REQUESTS.getFeaturedEvent();
		// localStorage.setItem('featuredEvent', JSON.stringify(data[0]));
		dispatch(setFeaturedEvent(data[0]));
	} catch (err) {
		console.log(err);
		// const collection = localStorage.getItem("featuredEvent");
		// dispatch(setFeaturedEvent(JSON.parse(collection)));
		// dispatch(setIsOffline());
		dispatch(setAlert(err.message, "error"));
	}
};

// Add event to database

export const addEvent = (formData, history) => async (dispatch) => {
	try {
		dispatch(setLoading());
		const updatedStudentChapter = formData.studentChapter.toLowerCase().replace(/ /g, "-");
		console.log(updatedStudentChapter);
		formData.studentChapter = updatedStudentChapter;

		const data = await REQUESTS.addEvent(formData);
		dispatch(setAddedEvent(data));
		dispatch(setAlert("Event Added!", "success"));
		// We can then pust to that event's page
		history.push(ADMIN_DASHBOARD);
	} catch (err) {
		console.log(err);
		dispatch(setAlert(err.message, "error"));
	}
};

// update an event
export const updateEvent = (id, formData, history) => async (dispatch) => {
	try {
		dispatch(setLoading());
		const updatedStudentChapter = formData.studentChapter.toLowerCase().replace(/ /g, "-");
		console.log(updatedStudentChapter);
		formData.studentChapter = updatedStudentChapter;
		const data = await REQUESTS.updateEvent(id, formData);
		dispatch(setUpdatedEvent(data));
		dispatch(setAlert("Event updated!", "success"));
		// We can then pust to that event's page
		history.push(ADMIN_DASHBOARD);
	} catch (err) {
		console.log(err);
		dispatch(setAlert(err.message, "error"));
	}
};

// delete an event
export const deleteEvent = (id, history) => async (dispatch) => {
	try {
		dispatch(setLoading());
		const data = await REQUESTS.deleteEvent(id);
		dispatch(setDeletedEvent(data));

		dispatch(setAlert("Event deleted!", "success"));
	} catch (err) {
		console.log(err);
		dispatch(setAlert(err.message, "error"));
	}
};
