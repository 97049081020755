import React from "react";
import PropTypes from "prop-types";
import Header from "./Header/Header";

const NotFound = (props) => {
	return <Header pageName="Not Found" />;
};

NotFound.propTypes = {};

export default NotFound;
