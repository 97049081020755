import React from "react";
import { makeStyles } from "@material-ui/core";
// import createEventStyles from "../../assets/jss/views/CreateEventStyles"; // import EventContainer from "../../components/CreateEvents/EventContainer";
import EventForm from "./EventForm";

// const useStyles = makeStyles(createEventStyles);

const CreateEvent = (props) => {
	// const classes = useStyles();
	return (
		<div>
			{/* <EventContainer /> */}
			<EventForm />
		</div>
	);
};

export default CreateEvent;
