import { withFormik } from "formik";
import { connect } from "react-redux";
import { login } from "../../../slices/authSlice";
import LoginForm from "./LoginForm";
import * as Yup from "yup";

//constants

const EnhancedLoginForm = withFormik({
	mapPropsToValues: () => ({
		email: "",
		password: "",
	}),
	validationSchema: Yup.object().shape({
		email: Yup.string().email("Email is invalid").required("Email is required!"),
		password: Yup.string()
			.required("Password is required!")
			.min(6, "Password must be 6 characters!"),
	}),
	handleSubmit: (values, { setSubmitting, props }) => {
		const { login } = props;
		login(values);
		console.log(values);
		setSubmitting(false);
	},
	displayName: "LoginForm",
})(LoginForm);

export default connect(null, { login })(EnhancedLoginForm);
