import React, { useEffect } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import {
	FormControl,
	Input,
	Text,
	Flex,
	Box,
	Alert,
	AlertDescription,
	Button,
	Heading,
} from "@chakra-ui/core";
import { Container, makeStyles } from "@material-ui/core";
import * as Yup from "yup";

//styles
import registrationStyles from "../../../assets/jss/views/RegistrationStyles";
import { useSelector, useDispatch } from "react-redux";
import { login } from "../../../slices/authSlice";
import { useHistory } from "react-router";
import { ADMIN_DASHBOARD } from "../../../constants/routes";

//components
import Header from "../../../components/Header/Header";
import EnhancedLoginForm from "./EnhancedLoginForm";

const useStyles = makeStyles(registrationStyles);

const Login = (props) => {
	const classes = useStyles();
	const {
		auth: { loading, isAuthenticated },
	} = useSelector((state) => {
		return {
			auth: state.auth,
		};
	});
	const dispatch = useDispatch();
	const history = useHistory();

	useEffect(() => {
		if (isAuthenticated) {
			history.push(ADMIN_DASHBOARD);
		}
	}, [isAuthenticated, history]);

	return (
		<Box
			bg="gray.100"
			py="4rem"
		>
		<Container maxWidth="sm">
			<Box
				boxShadow="0px 0px 20px rgba(227, 227, 227, 1)"
				rounded="lg"
				overflow="hidden"
				bg="white"
			>
				<Heading textAlign="center" size="lg" mt='4rem' mb="0.5rem">
						Login
				</Heading>
				<Text color="gray.500" textAlign="center" mb="2rem">
						Enter proper login credentials to
						<br/>access the Admin Dashboard.	
				</Text>
				<Box>
					<EnhancedLoginForm />
				</Box>
			</Box>
			</Container>
		</Box>	
	);
};

export default Login;
