import img from "../../images/footer svg/bg.svg";
const FooterStyles = (theme) => ({
	root: {
		"& > * ": {
			marginTop: theme.spacing(4),
			width: "25ch",
		},
	},

	footer: {
		backgroundImage: `url(${img})`,
		bottom: 0,
		left: 0,
		width: "100%",
		height: "110%",
		backgroundColor: theme.palette.primary.main,
		color: "white",
		padding: "3rem 0",
	},

	footerContainer: {
		padding: "1rem",
		display: "flex",
		justifyContent: "space-between",
		[theme.breakpoints.down("sm")]: {
			flexDirection: "column",
		},
	},
	footerSection: {
		paddingRight: "1rem",
		[theme.breakpoints.down("sm")]: {
			marginBottom: "1rem",
		},
	},
	footerAddress: {
		marginTop: "1.5rem",
		[theme.breakpoints.down("sm")]: {
			marginTop: "2rem",
		},
	},
	headings: {
		fontFamily: "Poppins",
		fontWeight: "bold",
		marginBottom: "1rem",
		fontSize: "1rem",
	},

	logo: {
		height: "70px",
	},
	link: {
		textDecoration: "underline",
		transition: "0.2s ease",
		"&:hover": {
			color: "#76E4F7",
		},
	},
});
export default FooterStyles;
