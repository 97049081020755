const studentChapterStyles = (theme) => ({
	root: {
		"& > * ": {
			marginTop: theme.spacing(4),
			width: "25ch",
		},
	},
	chapCard: {
		transition: "all 0.2s ease",
		cursor: "pointer",
		"&:hover": {
			transform: "scale(1.05)",
		},
	},
});
export default studentChapterStyles;
