export const MANIPAL_UNIVERSITY = "Manipal University Jaipur";

export const AMITY_UNIVERSITY = "Amity University Jaipur";

export const UEM_UNIVERSITY = "University of Engineering & Management Rajasthan";

export const LNMIIT_UNIVERSITY = "LNM Institute Of Information Technology";

export const MNIT_UNIVERSITY = "Malaviya National Institute of Technology Jaipur";

export const JECRC_UNIVERSITY = "JECRC University";

export const POORNIMA_UNIVERSITY = "Poornima College Of Engineering";

export const MUJ_PROFESSIONAL_CHAPTER = "Jaipur Professional Chapter";

// chapter slug
// student chapters

export const MANIPAL_SLUG = `manipal-university-jaipur`;

export const AMITY_SLUG = `amity-university-jaipur`;

export const UEM_SLUG = `university-of-engineering-and-management-rajasthan`;

export const LNMIIT_SLUG = `lnm-institute-of-information-technology`;

export const MNIT_SLUG = `malaviya-national-institute-of-technology-jaipur`;

export const JECRC_SLUG = `jecrc-university`;

export const POORNIMA_SLUG = `poornima-college-of-engineering`;

export const PROFESSIONAL_CHAPTER_SLUG = "jaipur-professional-chapter";
