export const HOME = "/";
export const OFFICERS = "/chapter-officers";
export const MEMBERSHIPS = "/memberships";
export const STUDENT_CHAPTERS = "/student-chapters";
export const PROFESSIONAL_CHAPTER_NEWS = "/professional-chapter-news";
export const NEWS = "/news";
export const AWARDS_OF_APPRECIATION = "/awards-of-appreciation";
export const CONTACT = "/contact";

// Auth
export const LOGIN = "/auth/kfvvrjvk/login";
// export const REGISTER = "/auth/login";

// student chapters

export const MANIPAL = `${STUDENT_CHAPTERS}/manipal-university-jaipur`;

export const AMITY = `${STUDENT_CHAPTERS}/amity-university-jaipur`;

export const UEM = `${STUDENT_CHAPTERS}/university-of-engineering-and-management-rajasthan`;

export const LNMIIT = `${STUDENT_CHAPTERS}/lnm-institute-of-information-technology`;

export const MNIT = `${STUDENT_CHAPTERS}/malaviya-national-institute-of-technology-jaipur`;

export const JECRC = `${STUDENT_CHAPTERS}/jecrc-university`;

export const POORNIMA = `${STUDENT_CHAPTERS}/poornima-college-of-engineering`;

// Admin
export const ADMIN_DASHBOARD = "/admin";
export const ADMIN_EVENTS = `${ADMIN_DASHBOARD}/events`;
export const ADMIN_EVENT_PAGE = `${ADMIN_DASHBOARD}/events/:slug`;
export const CREATE_EVENT = `${ADMIN_DASHBOARD}/events/create-event`;
export const EDIT_EVENT = `${ADMIN_DASHBOARD}/events/:slug/edit`;
