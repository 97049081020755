import React from "react";
import { makeStyles } from "@material-ui/core";

const styles = makeStyles((theme) => ({
	box: {
		cursor: "pointer",
		borderRadius: "10px 10px 10px 10px",
		background: "linear-gradient(94deg, rgba(232, 232, 232, 1) 0%, rgba(242, 242, 242, 1) 100%)",
		boxShadow: "0px 3px 20px rgba(100, 100, 100, 0.1)",
		color: "black",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		fontWeight: "500",
		transition: "0.3s ease",
		"&:hover": {
			transform: "scale(1.02)",
			boxShadow: "0px 3px 20px rgba(100, 100, 100, 0.3)",
		},
	},
}));

export const SecondaryDiv = ({ onClick, ...props }) => {
	const classes = styles();
	return (
		<div
			style={{
				borderRadius: props.borderRadius,
				fontSize: props.fontsize,
				margin: props.margin,
				padding: props.padding,
				width: props.width,
				height: props.height,
			}}
			className={classes.box}
			onClick={onClick}
		>
			{props.children}
		</div>
	);
};
