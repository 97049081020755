import React, { useEffect } from "react";
import { Heading, Box, Flex } from "@chakra-ui/core";
import Carousel from "../../components/Carousel/Carousel";
import Card from "../../components/Carousel/Card";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { PROFESSIONAL_CHAPTER_SLUG } from "../../constants/chapterNames";
import { Container } from "@material-ui/core";
import { getAllEvents } from "../../slices/eventSlice";

export const EventCarousel = () => {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(getAllEvents());
	}, []);
	const {
		event: { events },
	} = useSelector((state) => {
		return {
			event: state.event,
		};
	}, shallowEqual);
	if (events === null) return <> </>;
	const professionalChapterEvents = events.filter(
		(event) => event.studentChapter === PROFESSIONAL_CHAPTER_SLUG
	);
	return (
		<Flex bg="gray.100">
			<Container>
				<Flex mb="5rem" justify="center" alignItems="center">
					<Box w="100%" maxW="1280px">
						<Heading textAlign="center" size="xl" mb="2rem">
							Events & News
						</Heading>
						<Carousel>
							{professionalChapterEvents.map((event, index) => (
								<Card
									key={index}
									name={event.name}
									content={event.description}
									image={event.image[0]}
								/>
							))}
						</Carousel>
					</Box>
				</Flex>
			</Container>
		</Flex>
	);
};
