import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Header from "../../../components/Header/Header";
import {
	SimpleGrid,
	Box,
	Image,
	PseudoBox,
	Button,
	Badge,
	Flex,
	Heading,
	Text,
} from "@chakra-ui/core";
import { Container, makeStyles } from "@material-ui/core";
import { Home } from "@material-ui/icons";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getAllEvents } from "../../../slices/eventSlice";
import Loader from "../../../components/Loader/Loader";

import EventsCard from "./EventCards";
import { GradientDiv } from "../../../components/GradientDiv";
import { CREATE_EVENT } from "../../../constants/routes";

const AdminDashboard = (props) => {
	const {
		event: { events, loading },
	} = useSelector((state) => {
		return {
			event: state.event,
		};
	}, shallowEqual);
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(getAllEvents());
	}, []);

	if (events === null || loading) return <Loader />;
	return (
		<div>
			<Header pageName="Admin Dashboard" />
			{/* <Flex p={["0", "0", "0", "4rem"]} pt="2rem" bg="gray.100" textAlign="center"> */}
			<Container width="sm">
				<Text
					color="brand.main"
					fontSize={25}
					margin="2rem"
					mt="5rem"
					fontWeight="bold"
					textAlign="center"
				>
					EVENTS
				</Text>
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "center",
						marginTop: 30,
						width: "100%",
					}}
				>
					<GradientDiv borderRadius="5px" width="120px" height="40px" margin={10}>
						{" "}
						<Link to={CREATE_EVENT} style={{ textDecoration: "none" }}>
							Create Event{" "}
						</Link>
					</GradientDiv>
				</div>
				<SimpleGrid
					mt="2rem"
					columns={{ sm: 1, md: 2, lg: 3 }}
					spacing={10}
					className="card-container"
				>
					{events.map((event) => {
						return <EventsCard key={event._id} event={event} />;
					})}
				</SimpleGrid>
				<br />
				<Flex direction={["column", "column", "column", "row"]}></Flex>
			</Container>
			{/* </Flex> */}
		</div>
	);
};

AdminDashboard.propTypes = {};

export default AdminDashboard;
