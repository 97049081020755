import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { GradientDiv } from "../../../components/GradientDiv";
import { Field, Form, ErrorMessage } from "formik";
import { FormControl, Input, Alert, AlertDescription, Flex, Box } from "@chakra-ui/core";
import { makeStyles } from "@material-ui/core";

//styles
import registrationStyles from "../../../assets/jss/views/RegistrationStyles";
import { useSelector } from "react-redux";

const useStyles = makeStyles(registrationStyles);

const LoginForm = (props) => {
	const classes = useStyles();
	const {
		auth: { loading },
	} = useSelector((state) => {
		return {
			auth: state.auth,
		};
	});
	const { values, errors, touched, handleSubmit, handleBlur, handleChange } = props;

	return (
		<Flex mb="4rem"  direction="column" justifyContent="center" align="center">
			<Form>
				<FormControl mb='1rem'>
					<div>
						<Field name="email" type="text">
							{({ field, form }) => (
								<Input
									isInvalid={form.touched.email && form.errors.email ? true : false}
									{...field}
									width="200px"
									mx="auto"
									placeholder="Email"
								/>
							)}
						</Field>
						<ErrorMessage name="email">
							{() => (
								<Alert status="error" variant="subtle" className={classes.alerts}>
									<AlertDescription className={classes.alertDescription}>
										{errors.email}
									</AlertDescription>
								</Alert>
							)}
						</ErrorMessage>
					</div>
				</FormControl>

				<FormControl>
					<div>
						<Field name="password">
							{({ field, form }) => (
								<Input
									type="password"
									isInvalid={form.touched.password && form.errors.password ? true : false}
									{...field}
									width="200px"
									
									placeholder="Password"
								/>
							)}
						</Field>

						<ErrorMessage name="password">
							{() => (
								<Alert status="error" variant="subtle" className={classes.alerts}>
									<AlertDescription className={classes.alertDescription}>
										{errors.password}
									</AlertDescription>
								</Alert>
							)}
						</ErrorMessage>
					</div>
				</FormControl>

				<Box
					
						display= "flex"
						flexDirection= "row"
						justifyContent= "center"
						alignItems= "center"
						marginTop= "1rem"
						width= "100%"
				
				>
					<GradientDiv
						borderRadius="5px"
						width="120px"
						height="40px"
						margin={10}
						loadingText="Logging in..."
						isLoading={loading}
						onClick={() => {
							document.forms[0].requestSubmit();
						}}
					>
						Submit
					</GradientDiv>
				</Box>
			</Form>
		</Flex>
	);
};

LoginForm.propTypes = {};

export default LoginForm;