import API from './api';

// get all events
export const getAllEvents = async () => {
  try {
    const res = await API.get('/events');
    return res.data;
  } catch (err) {
    throw err;
  }
};

// get all events
export const getAllEventsForStudentChapter = async (studentChapter) => {
  try {
    const res = await API.get(`/events/${studentChapter}`);
    return res.data;
  } catch (err) {
    throw err;
  }
};

// get event by id
export const getEventById = async (id) => {
  try {
    const res = await API.get(`/events/${id}`);
    return res.data;
  } catch (err) {
    throw err;
  }
};

// get event by slug
export const getEventBySlug = async (slug) => {
  try {
    const res = await API.get(`/events/slug/${slug}`);
    return res.data;
  } catch (err) {
    throw err;
  }
};

// get featured event
export const getFeaturedEvent = async () => {
  try {
    const res = await API.get('/events/latest-event');

    return res.data;
  } catch (err) {
    throw err;
  }
};
// Add new event
export const addEvent = async (formData) => {
  try {
    const res = await API.post('/events', formData);
    return res.data;
  } catch (err) {
    throw err;
  }
};

// update event
export const updateEvent = async (id, formData) => {
  try {
    const res = await API.put(`/events/${id}`, formData);
    return res.data;
  } catch (err) {
    throw err;
  }
};

// delete Event
export const deleteEvent = async (id) => {
  try {
    const res = await API.delete(`/events/${id}`);
    return res.data;
  } catch (err) {
    throw err;
  }
};
