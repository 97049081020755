//@done
import React, { useState, useEffect } from "react";
import Form1 from "./Form1";
import Form3 from "./Form3";
import { useParams } from "react-router";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { getEventBySlug } from "../../api/eventRequests";
import { setLoading } from "../../slices/authSlice";
import Loader from "../../components/Loader/Loader";
import {
	MANIPAL_UNIVERSITY,
	UEM_UNIVERSITY,
	AMITY_UNIVERSITY,
	JECRC_UNIVERSITY,
	LNMIIT_UNIVERSITY,
	MNIT_UNIVERSITY,
	POORNIMA_UNIVERSITY,
	MUJ_PROFESSIONAL_CHAPTER,
} from "../../constants/chapterNames";
const EventForm = () => {
	const [step, setStep] = useState(1);
	const params = useParams();
	const dispatch = useDispatch();
	const {
		event: { loading },
	} = useSelector((state) => {
		return {
			event: state.event,
		};
	}, shallowEqual);
	const nextStep = () => {
		setStep(step + 1);
	};

	const prevStep = () => {
		setStep(step - 1);
	};

	const [formData, setFormData] = useState({
		id: -1,
		eventName: "",
		eventDescription: "",
		eventFromDate: "",
		eventToDate: "",
		minTeamSize: "",
		maxTeamSize: "",
		eventFee: "",
		eventPrize: "",
		eventRules: "",
		contactName1: "",
		contactPhone1: "",
		contactName2: "",
		contactPhone2: "",
		eventSheetId: "",
	});
	const chapterNames = [
		MANIPAL_UNIVERSITY,
		UEM_UNIVERSITY,
		AMITY_UNIVERSITY,
		JECRC_UNIVERSITY,
		LNMIIT_UNIVERSITY,
		MNIT_UNIVERSITY,
		POORNIMA_UNIVERSITY,
		MUJ_PROFESSIONAL_CHAPTER,
	];
	useEffect(() => {
		(async () => {
			if (params.slug) {
				dispatch(setLoading());
				const event = await getEventBySlug(params.slug);
				setFormData({
					...formData,
					id: event[0]._id,
					eventImage: event[0].image,
					eventName: event[0].name,
					eventDescription: event[0].description,
					eventFromDate: event[0].date.from,
					eventToDate: event[0].date.to,
					contactName1: event[0].contact[0].name,
					contactPhone1: event[0].contact[0].number,
					contactName2: event[0].contact[1].name,
					contactPhone2: event[0].contact[1].number,
					studentChapter: chapterNames.find(
						(chapter) => chapter.toLowerCase().replace(/ /g, "-") === event[0].studentChapter
					),
				});
				dispatch(setLoading());
			}
		})();
	}, []);

	const handleStateChange = (values) => {
		setFormData({
			...formData,
			...values,
			step,
		});
	};

	if (loading) return <Loader />;
	switch (step) {
		case 1:
			return (
				// <div style={{ backgroundImage: `url(${pen_bg})`, backgroundSize: "cover" }}>
				<Form1
					nextStep={nextStep}
					handleStateChange={handleStateChange}
					values={formData}
					// openWidget={openWidget}
				/>
				// </div>
			);
		case 2:
			return (
				// <div style={{ backgroundImage: `url(${pen_bg})`, backgroundSize: "cover" }}>
				<Form3 prevStep={prevStep} values={formData} />
				// </div>
			);
		default:
			return <div />;
	}
};

export default EventForm;
