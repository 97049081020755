import zIndex from "@material-ui/core/styles/zIndex";

const headerStyles = (theme) => ({
	root: {
		"& > * ": {
			marginTop: theme.spacing(4),
			width: "25ch",
		},
	},
	"@keyframes fadeIn": {
		from: { opacity: 0 },
		to: { opacity: 1, transform: "rotate(360deg)" },
	},
	acmImage: {
		animationName: "$fadeIn",
		animationDuration: "2s",
		animationTimingFunction: "cubic-bezier(1, 0, 0, 1)",
		// '2s', 'cubic-bezier(1, 0, 0, 1)'],
	},
	"@keyframes blinker": {
		form: { opacity: 0 },
		to: { opacity: 1 },
	},
	blinkText: {
		animationName: "$blinker",
		animationDuration: "1s",
		animationTimingFunction: "linear",
		animationIterationCount: "infinite",
	},
	svg: {
		zIndex: "-100",
		position: "absolute",
		pointerEvents: "none",
	},
});
export default headerStyles;
