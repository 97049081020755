import React from "react";
import { Box } from "@chakra-ui/core";
import { AiFillCaretRight, AiFillCaretLeft } from "react-icons/ai";
export const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <Box
      as={AiFillCaretRight}
      className={className}
      style={{
        ...style,

        width: "32px",
        height: "32px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "50%",
        zIndex: "500",
        position: "absolute",
        right: "-3rem",
        color: "#393b44",
        background: "#f1f3f8",
      }}
      onClick={onClick}
    />
  );
};

export const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <Box
      as={AiFillCaretLeft}
      className={className}
      style={{
        ...style,

        width: "32px",
        height: "32px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "50%",
        zIndex: "500",
        position: "absolute",
        left: "-3rem",
        color: "#393b44",
        background: "#f1f3f8",
      }}
      onClick={onClick}
    />
  );
};
