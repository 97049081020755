import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Box, Flex, Heading, Image, Text } from "@chakra-ui/core";
import ACM_LOGO from "../../assets/images/acm3.png";
import { makeStyles } from "@material-ui/core";
import Blink from "react-blink-text";
import headerStyles from "../../assets/jss/components/HeaderStyles";
import BubbleParticle from "./BubbleParticle";
import anime from "animejs";
//images
import waves from "../../assets/images/header/waves.svg";

const useStyles = makeStyles(headerStyles);

const Header = ({ pageName, pageSubHeading, additionalText, image, children }) => {
	const classes = useStyles();

	useEffect(() => {
		anime
			.timeline({ loop: false })
			.add({
				targets: ".ml15 .word",
				scale: [14, 1],
				opacity: [0, 1],
				easing: "easeOutCirc",
				duration: 800,
				delay: (el, i) => 800 * i,
			})
			.add({
				targets: ".ml15",
				opacity: 1,
				duration: 1000,
				easing: "easeOutExpo",
				delay: 1000,
			});
	}, []);

	return (
		<Box overflow="hidden" position="relative">
			<BubbleParticle height={"550px"} />
			<section>
				<Box
					w="100%"
					bg="brand.main"
					pos="relative"
					top="0"
					left="0"
					zIndex="1"
					margin="0 auto"
					// w="fit-content"
					mx="auto"
				>
					<Image width="105%" left="-10px" top="-40%" className={classes.svg} src={waves} />

					<Flex zIndex="200" justify="center" align="center" direction="column" pt="5rem" pb="5rem">
						{children ? (
							children
						) : (
							<>
								<Image className={classes.acmImage} src={image} alt="" style={{ width: 180 }} />
								<Heading
									className="ml15"
									textAlign="center"
									letterSpacing="3px"
									fontWeight="bold"
									fontFamily="Poppins"
									textTransform="uppercase"
									color="white"
									padding="1rem"
									style={{ margin: "0 auto" }}
								>
									{pageName.split(" ").map((name) => {
										return <div className="word">{`${name}  `} </div>;
									})}
								</Heading>
								<Text
									textAlign="center"
									lineHeight="1.5em"
									mt="-0.5rem"
									width={["80%", "80%", "60%", "50%"]}
									letterSpacing="0.1em"
									fontSize="1.1rem"
									color="white"
									fontWeight="400"
									// display={["none", "none", "block", "block"]}
								>
									{pageSubHeading}
								</Text>
								{additionalText && (
									<Text
										className={classes.blinkText}
										textAlign="center"
										lineHeight="1.5em"
										mt="1rem"
										width={["80%", "80%", "60%", "50%"]}
										letterSpacing="0.1em"
										fontSize="1.1rem"
										color="white"
										// display={["none", "none", "block", "block"]}
									>
										<Blink color="white" text={additionalText} fontSize="20">
											<span>{additionalText}</span>
										</Blink>
									</Text>
								)}
							</>
						)}
					</Flex>
				</Box>
			</section>
		</Box>
	);
};
Header.defaultProps = {
	image: ACM_LOGO,
};
Header.propTypes = {
	pageName: PropTypes.string.isRequired,
	pageSubHeading: PropTypes.string,
};

export default Header;
