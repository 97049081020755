import * as React from "react";
import { Button, Flex, Image, Text, Badge, Box } from "@chakra-ui/core";
import { makeStyles } from "@material-ui/core";
import studentChapterStyles from "../../../assets/jss/views/StudentChapterStyles";
import { Link, useHistory } from "react-router-dom";
import { STUDENT_CHAPTERS } from "../../../constants/routes";
import { useDispatch } from "react-redux";
import {
	MANIPAL_SLUG,
	AMITY_SLUG,
	JECRC_SLUG,
	LNMIIT_SLUG,
	MNIT_SLUG,
	POORNIMA_SLUG,
	UEM_SLUG,
} from "../../../constants/chapterNames";
import { deleteEvent } from "../../../slices/eventSlice";
import { ADMIN_DASHBOARD, ADMIN_EVENTS } from "../../../constants/routes";

const useStyles = makeStyles(studentChapterStyles);

const EventsCard = ({ event }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const history = useHistory();

	const handleDelete = () => {
		dispatch(deleteEvent(event._id, history));
	};
	const getColor = (event) => {
		switch (event.studentChapter) {
			case MANIPAL_SLUG:
				return "orange";
			case AMITY_SLUG:
				return "yellow";
			default:
				return "pink";
		}
	};
	return (
		<>
			<Flex
				bg="white"
				my={["1.5rem", "1rem", "1rem"]}
				mx="1rem"
				maxH={["600px"]}
				rounded="lg"
				overflow="hidden"
				justify="center"
				align="center"
				textAlign="center"
				direction="column"
				boxShadow="0px 0px 20px rgba(227, 227, 227, 1)"
				cursor="grab"
			>
				<Flex
					overflow="hidden"
					alignItems="flex-start"
					justifyContent="center"
					h={["340px", "340px", "360px"]}
				>
					<Image src={event.image[0]} object-fit="cover" h="110%" />
				</Flex>
				<Flex justify="center" alignItems="center" direction="column" m="1rem" w="100%">
					<Text px="2rem" textAlign="center" mb="0rem" fontWeight="700" fontSize="xl">
						{event.name}
					</Text>
					<Badge
						style={{ wordBreak: "break-word" }}
						m="0.5rem"
						ml="1rem"
						mr="1rem"
						fontSize="0.6em"
						variantColor={getColor(event)}
					>
						{event.studentChapter.replace(/-/g, " ")}
					</Badge>
					<Text textAlign="left" px="2rem" mb="2rem" color="gray.500">
						{event.description.slice(0, 200) + " ..."}
					</Text>
					{/* Link to the page */}
					<Button
						cursor="pointer"
						position="relative"
						color="tomato"
						bg="white"
						variant="ghost"
						size="sm"
						border="0px"
						rounded="lg"
						fontWeight="bold"
						_hover={{
							backgroundColor: "#e3f2fd",
							// transform: "scale(1.05)",
						}}
						onClick={handleDelete}
					>
						DELETE
					</Button>
					<Link to={`${ADMIN_EVENTS}/${event.slug}/edit`} style={{ textDecoration: "none" }}>
						<Button
							cursor="pointer"
							position="relative"
							color="yellow.300"
							bg="white"
							variant="ghost"
							size="sm"
							border="0px"
							rounded="lg"
							fontWeight="bold"
							_hover={{
								backgroundColor: "#e3f2fd",
								// transform: "scale(1.05)",
							}}
						>
							EDIT
						</Button>
					</Link>
				</Flex>
			</Flex>
		</>
	);
};

export default EventsCard;
